import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Salesforce = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Salesforce"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">TEQT is a trusted Partner to Salesforce (MuleSoft) since 2008, delivering domain-specific, business outcome focused solutions to our Global customers. Equipped with 300+ Multi-Certified Salesforce experts having a rich mix of domain & technical knowledge, we are ready to work with your business to help you unlock the true potential of the Salesforce ecosystem, enabling your business in making the right decisions, reducing risks and getting the maximum return on of your investment in Salesforce.</p>
                        <p className="mt-3">
                            Key industry areas where TEQT has been helping our clients:
                        </p>

                        <ul className="circle-list">
                            <li className="mt-2">Lending & Mortgage transformation for Banking and Financial Services.</li>
                            <li className="mt-2">Account and Lead optimization for Commercial Banks</li>
                            <li className="mt-2">Broker Management for P&C and Specialty Insurance Companies</li>
                            <li className="mt-2">Claims experience transformation for Specialty Insurers</li>
                            <li className="mt-2">Service Transformation for Wealth and Banking</li>
                            <li className="mt-2">Trip Disruption services for Airlines and Rail</li>
                            <li className="mt-2">Cargo Claims transformation for transport providers</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query SalesforcePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Salesforce.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Salesforce;
